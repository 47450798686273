import { TextField as EnvirioTextField } from '@120wateraudit/envirio-components'
import React, { CSSProperties, FC, useEffect, useRef } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormField, Input, Icon, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

type Props = FieldRenderProps<string, HTMLElement> & {
  className?: string
  displayRequiredAsterisk?: boolean
  displayConditionallyRequired?: boolean
  hideError?: boolean
  label?: string
  style?: CSSProperties
  stateValidation?: boolean
  tooltip?: string
  useDefaultBlacklist?: boolean
  overrideBlacklistValues?: string
}

const TextField: FC<Props> = ({
  className,
  input,
  meta: { error, pristine, touched },
  style,
  stateValidation,
  useDefaultBlacklist = true,
  overrideBlacklistValues,
  ...rest
}) => {
  const inputRef = useRef<Input>(null)
  useEffect(() => {
    if (rest.autoFocus) {
      inputRef.current?.focus()
    }
  }, [inputRef, rest.autoFocus])

  return (
    <FieldWithErrors
      {...input}
      useDefaultOnBlur
      useDefaultBlacklist={useDefaultBlacklist}
      overrideBlacklistValues={overrideBlacklistValues}
      error={!pristine && touched && !!error}
      value={(input.value as unknown) === 0 ? '0' : input.value}
      {...rest}
      className={className}
      inputRef={inputRef}
      style={style}
      stateValidation={stateValidation}
    />
  )
}

const FieldWithErrors = styled(EnvirioTextField)`
&.field {
  border-color: ${({ stateValidation }) =>
    stateValidation ? '#ffc720' : 'rgba(0, 0, 0, 0.25)'}!important;`

export const TritonTextField = ({
  displayRequiredAsterisk,
  displayConditionallyRequired,
  className,
  hideError,
  input,
  label,
  meta: { error },
  style,
  stateValidation,
  useDefaultBlacklist = true,
  overrideBlacklistValues,
  ...rest
}: Props & { label: string }): JSX.Element => {
  const showConditionalAsterisk = !!(error && displayConditionallyRequired)
  const inputRef = useRef<Input>(null)
  const labelRef = useRef(null)
  useEffect(() => {
    if (rest.autoFocus) {
      inputRef.current?.focus()
    }
  }, [inputRef, rest.autoFocus])

  return (
    <Tooltip
      content={rest.tooltip}
      disabled={!rest.tooltip}
      context={labelRef}
      hideOnScroll
      trigger={
        <Field
          className={className}
          style={style}
          stateValidation={stateValidation}>
          <label ref={labelRef}>
            {label}
            {(displayRequiredAsterisk || showConditionalAsterisk) && (
              <sup>
                <Icon
                  color="red"
                  name="asterisk"
                  style={{ fontSize: '6px', marginLeft: '1px' }}
                />
              </sup>
            )}
          </label>
          <EnvirioTextField
            {...input}
            useDefaultOnBlur
            useDefaultBlacklist={useDefaultBlacklist}
            overrideBlacklistValues={overrideBlacklistValues}
            onChange={input.onChange}
            error={!!error}
            value={(input.value as unknown) === 0 ? '0' : input.value}
            {...rest}
            inputRef={inputRef}
          />
          {error && !hideError && <ValidationError>{error}</ValidationError>}
        </Field>
      }
    />
  )
}

const Field = styled(FormField)`
  &&&.field {
    & > label {
      color: #333;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 4px;
    }
    .ui.input {
      border-color: ${({ error, stateValidation }) =>
        error
          ? 'var(--ignis-red)'
          : stateValidation
          ? '#ffc720'
          : 'rgba(0, 0, 0, 0.25)'} !important;
    }
  }
` as typeof FormField

const Tooltip = styled(Popup)`
  position: absolute;
  top: 50px;
`

const ValidationError = styled.p`
  position: absolute;
  color: var(--ignis-red);
`

export default TextField
