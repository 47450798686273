import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Field, useField, useForm } from 'react-final-form'

import {
  useServiceLineDiameters,
  useServiceLineMaterials
} from 'src/containers/ServiceLines/dataAccess'
import {
  getDiameterDropdownOptions,
  getServiceLineLeadSolderLeadLinedMaterialIds
} from 'src/utils/serviceLineUtils'
import DatePicker from 'src/components/Forms/Datepicker'
import { TritonDropdown } from 'src/components/Forms/Dropdown'
import { TritonTextField as TextField } from 'src/components/Forms/TextField'
import { Location } from 'src/types/Location'
import LocationLookup from 'src/modules/Locations/Lookup'
import { convertEnumToListItems } from 'src/utils/selectlist'
import { YesNoUnknown } from 'src/types/enums/YesNoUnknown'
import { useMaterialDropdownOptionsByOwnership } from 'src/hooks/useRemoveDropdownOptions'
import { useEntityDefinitions } from 'src/router/UserProvider'
import {
  getSystemOwnedVerificationMethodEnums,
  getCustomerOwnedVerificationMethodEnums,
  getSystemOwnedClassificationBasisEnums,
  getCustomerOwnedClassificationBasisEnums,
  getFittingsVerificationMethodEnums
} from 'src/utils/entityDefintions/serviceLineEnums'

interface Props {
  className?: string
  type: 'edit' | 'create'
  stateValidationErrorsList?: string[]
}

const AssetDrawerForm = ({
  className,
  type,
  stateValidationErrorsList
}: Props): JSX.Element => {
  const { data: materials } = useServiceLineMaterials()
  const { data: diameters } = useServiceLineDiameters()
  const entityDefinitions = useEntityDefinitions()
  const leadSolderLeadLinedMaterialIds =
    getServiceLineLeadSolderLeadLinedMaterialIds(
      materials?.serviceLineMaterials ?? []
    )

  const diameterOptions = getDiameterDropdownOptions(
    diameters?.serviceLineDiameters ?? []
  )

  const yesNoUnknownDropdown = convertEnumToListItems(YesNoUnknown)

  const yesNoUnknownCustomOptions = [
    { key: 'Unknown', text: 'Unknown', value: 'Unknown' },
    { key: 'Yes', text: 'Yes', value: true },
    { key: 'No', text: 'No', value: false }
  ]

  const { change } = useForm()
  const privateDiameter = useField<number>(`privateLineDiameter`).input.value
  const publicDiameter = useField<number>(`publicLineDiameter`).input.value
  const ownership = useField<number>('ownership').input.value
  const privateMaterial = useField<number>('privateMaterial').input.value
  const publicMaterial = useField<number>('publicMaterial').input.value

  const isUnknownPrivateDiameter =
    useField<number>(`privateLineDiameter`).input.value !== 1
  const isUnknownPublicDiameter =
    useField<number>(`publicLineDiameter`).input.value !== 1

  const unknownMaterialValue = materials?.serviceLineMaterials.filter(
    val => val.selectionValue === 'Unknown - Material Unknown'
  )
  const unknownMaterialId = unknownMaterialValue?.[0].id

  useEffect(() => {
    if (privateDiameter >= 2 && privateDiameter <= 10) {
      change('privateDiameterOverTwoInches', false)
    }
    if (privateDiameter > 10) {
      change('privateDiameterOverTwoInches', true)
    }
  }, [change, privateDiameter, type])

  useEffect(() => {
    if (publicDiameter >= 2 && publicDiameter <= 10) {
      change('publicDiameterOverTwoInches', false)
    }
    if (publicDiameter > 10) {
      change('publicDiameterOverTwoInches', true)
    }
  }, [change, publicDiameter, type])

  useEffect(() => {
    if (privateMaterial === unknownMaterialId) {
      change('privateClassificationBasis', 'NotAvailable')
    }
    if (publicMaterial === unknownMaterialId) {
      change('publicClassificationBasis', 'NotAvailable')
    }
  }, [change, privateMaterial, publicMaterial, type])

  const {
    input: { onChange }
  } = useField<number>('locationId')
  const setLocation = useCallback(
    (payload?: Location) => onChange(payload?.id),
    [onChange]
  )

  const fittingsOptions = convertEnumToListItems(YesNoUnknown)

  const hasStateValidationErrors = val =>
    stateValidationErrorsList?.includes(val)

  return (
    <Head>
      <Field<string>
        component={StyledTextField}
        label="External ID"
        name="externalId"
        id="externalId"
        stateValidation={hasStateValidationErrors('externalId')}
      />
      {type === 'create' && (
        <SearchWrapper>
          <StyledLabel>Address</StyledLabel>
          <Search
            className={className}
            fluid={true}
            label="Address"
            onSelectLocation={setLocation}
            placeholder="Search for an address"
            id="address"
          />
        </SearchWrapper>
      )}
      {type === 'edit' && (
        <Field<string>
          component={StyledTextField}
          label="Address"
          name="address"
          disabled="true"
          id="address"
          stateValidation={hasStateValidationErrors('address')}
        />
      )}
      <Field<string>
        component={StyledTextField}
        label="Description"
        name="description"
        id="description"
        stateValidation={hasStateValidationErrors('description')}
        useDefaultBlacklist={false}
      />
      <Field<string>
        component={StyledDropdown}
        label="System-Owned Classification Basis"
        name="publicClassificationBasis"
        options={getSystemOwnedClassificationBasisEnums('', entityDefinitions)}
        search
        id="publicClassificationBasis"
        stateValidation={hasStateValidationErrors('publicClassificationBasis')}
      />
      <Field<string>
        component={StyledDropdown}
        label="System-Owned Material"
        name="publicMaterial"
        options={useMaterialDropdownOptionsByOwnership('public', ownership)}
        search
        id="publicMaterial"
        stateValidation={hasStateValidationErrors('publicMaterial')}
      />
      <Field name="publicMaterial" subscription={{ value: true }}>
        {({ input: { value } }) =>
          leadSolderLeadLinedMaterialIds?.includes(value) ? (
            <Field<string>
              component={StyledDropdown}
              label="System-Owned Lead Solder"
              name="publicLeadSolderLeadLined"
              options={yesNoUnknownDropdown}
              id="publicLeadSolderLeadLined"
            />
          ) : null
        }
      </Field>
      <Field<string>
        component={StyledDropdown}
        label="System-Owned Previously Lead"
        name="publicPreviouslyLead"
        options={yesNoUnknownDropdown}
        id="publicPreviouslyLead"
        stateValidation={hasStateValidationErrors('publicPreviouslyLead')}
      />
      <Field<string>
        component={StyledDatePicker}
        label="System-Owned Install Date"
        name="publicInstalledDate"
        id="publicInstalledDate"
        stateValidation={hasStateValidationErrors('publicInstalledDate')}
      />
      <Field<string>
        component={StyledDropdown}
        label="System-Owned Verification Method"
        name="publicVerificationMethod"
        id="publicVerificationMethod"
        options={getSystemOwnedVerificationMethodEnums('', entityDefinitions)}
        stateValidation={hasStateValidationErrors('publicVerificationMethod')}
        search
      />
      <Field<string>
        component={StyledDatePicker}
        label="System-Owned Verification Date"
        name="publicVerifiedDate"
        id="publicVerifiedDate"
        stateValidation={hasStateValidationErrors('publicVerifiedDate')}
      />
      <Field<string>
        component={StyledTextField}
        label="System-Owned Verified By"
        name="publicVerifiedBy"
        id="publicVerifiedBy"
        stateValidation={hasStateValidationErrors('publicVerifiedBy')}
      />
      <Field<string>
        component={StyledTextField}
        label="System-Owned Depth (in)"
        min="0"
        name="publicLineDepth"
        placeholder="0"
        step="0.01"
        type="number"
        id="publicLineDepth"
        stateValidation={hasStateValidationErrors('publicLineDepth')}
      />
      <Field<string>
        component={StyledDropdown}
        label="System-Owned Diameter (in)"
        name="publicLineDiameter"
        options={diameterOptions}
        search
        id="publicLineDiameter"
        stateValidation={hasStateValidationErrors('publicLineDiameter')}
      />
      <Field<string>
        component={StyledDropdown}
        label='System-Owned Diameter Greater Than 2"?'
        name="publicDiameterOverTwoInches"
        id="publicDiameterOverTwoInches"
        options={yesNoUnknownCustomOptions}
        search
        disabled={isUnknownPublicDiameter}
      />
      <Field<string>
        component={StyledDatePicker}
        label="System-Owned Removal Date"
        name="publicRemovalDate"
        id="publicRemovalDate"
        stateValidation={hasStateValidationErrors('publicRemovalDate')}
      />
      <Field<string>
        component={StyledTextField}
        label="System-Owned Notes"
        name="publicNotes"
        id="publicNotes"
        stateValidation={hasStateValidationErrors('publicNotes')}
        useDefaultBlacklist={false}
      />
      <Field<string>
        component={StyledDropdown}
        label="Customer-Owned Classification Basis"
        name="privateClassificationBasis"
        id="privateClassificationBasis"
        options={getCustomerOwnedClassificationBasisEnums(
          '',
          entityDefinitions
        )}
        search
        stateValidation={hasStateValidationErrors('privateClassificationBasis')}
      />
      <Field<string>
        component={StyledDropdown}
        label="Customer-Owned Material"
        name="privateMaterial"
        id="privateMaterial"
        options={useMaterialDropdownOptionsByOwnership('private', ownership)}
        search
        stateValidation={hasStateValidationErrors('privateMaterial')}
      />
      <Field name="privateMaterial" subscription={{ value: true }}>
        {({ input: { value } }) =>
          leadSolderLeadLinedMaterialIds?.includes(value) ? (
            <Field<string>
              component={StyledDropdown}
              label="Customer-Owned Lead Solder"
              name="privateLeadSolderLeadLined"
              id="privateLeadSolderLeadLined"
              options={yesNoUnknownDropdown}
            />
          ) : null
        }
      </Field>
      <Field<string>
        component={StyledDatePicker}
        label="Customer-Owned Install Date"
        name="privateInstalledDate"
        id="privateInstalledDate"
        stateValidation={hasStateValidationErrors('privateInstalledDate')}
      />
      <Field<string>
        component={StyledDropdown}
        label="Customer-Owned Verification Method"
        name="privateVerificationMethod"
        id="privateVerificationMethod"
        options={getCustomerOwnedVerificationMethodEnums('', entityDefinitions)}
        search
        stateValidation={hasStateValidationErrors('privateVerificationMethod')}
      />
      <Field<string>
        component={StyledDatePicker}
        label="Customer-Owned Verification Date"
        name="privateVerifiedDate"
        id="privateVerifiedDate"
        stateValidation={hasStateValidationErrors('privateVerifiedDate')}
      />
      <Field<string>
        component={StyledTextField}
        label="Customer-Owned Verified By"
        name="privateVerifiedBy"
        id="privateVerifiedBy"
        stateValidation={hasStateValidationErrors('privateVerifiedBy')}
      />
      <Field<string>
        component={StyledTextField}
        label="Customer-Owned Depth (in)"
        min="0"
        name="privateLineDepth"
        placeholder="0"
        step="0.01"
        type="number"
        id="privateLineDepth"
        stateValidation={hasStateValidationErrors('privateLineDepth')}
      />
      <Field<string>
        component={StyledDropdown}
        label="Customer-Owned Diameter (in)"
        name="privateLineDiameter"
        id="privateLineDiameter"
        options={diameterOptions}
        search
        stateValidation={hasStateValidationErrors('privateLineDiameter')}
      />
      <Field<string>
        component={StyledDropdown}
        label='Customer-Owned Diameter Greater Than 2"?'
        name="privateDiameterOverTwoInches"
        id="privateDiameterOverTwoInches"
        options={yesNoUnknownCustomOptions}
        search
        disabled={isUnknownPrivateDiameter}
      />
      <Field<string>
        component={StyledDatePicker}
        label="Customer-Owned Removal Date"
        name="privateRemovalDate"
        id="privateRemovalDate"
        stateValidation={hasStateValidationErrors('privateRemovalDate')}
      />
      <Field<string>
        component={StyledTextField}
        label="Customer-Owned Notes"
        name="privateNotes"
        id="privateNotes"
        stateValidation={hasStateValidationErrors('privateNotes')}
        useDefaultBlacklist={false}
      />
      <Field<string>
        component={StyledDropdown}
        label="Lead Fittings"
        name="leadFittings"
        id="leadFittings"
        options={fittingsOptions}
        stateValidation={hasStateValidationErrors('leadFittings')}
      />
      <Field<string>
        component={StyledDropdown}
        label="Lead Solder"
        name="leadSolder"
        options={yesNoUnknownDropdown}
        id="leadSolder"
        stateValidation={hasStateValidationErrors('leadSolder')}
      />
      <Field<string>
        component={StyledDropdown}
        label="Fittings Verification Method"
        name="fittingsVerificationMethod"
        options={getFittingsVerificationMethodEnums('', entityDefinitions)}
        search
        id="fittingsVerificationMethod"
        stateValidation={hasStateValidationErrors('fittingsVerificationMethod')}
      />
      <Field<string>
        component={StyledDatePicker}
        label="Fittings Verification Date"
        name="fittingsVerifiedDate"
        id="fittingsVerifiedDate"
        stateValidation={hasStateValidationErrors('fittingsVerifiedDate')}
      />
      <Field<string>
        component={StyledTextField}
        label="Fittings Verified By"
        name="fittingVerifiedBy"
        id="fittingVerifiedBy"
        stateValidation={hasStateValidationErrors('fittingVerifiedBy')}
      />
      <Field<string>
        component={StyledTextField}
        label="Other Lead Equipment"
        name="otherLeadEquipment"
        id="otherLeadEquipment"
        stateValidation={hasStateValidationErrors('otherLeadEquipment')}
        useDefaultBlacklist={false}
      />
    </Head>
  )
}

const Head = styled.div`
  scroll-padding-top: 20px;
`

const StyledTextField = styled(TextField)`
  &&& {
    margin: 24px;
  }
`

const StyledDropdown = styled(TritonDropdown)`
  &&& {
    margin: 24px;
  }
`

const StyledDatePicker = styled(DatePicker)`
  &&& {
    margin: 24px;
  }
`

const Search = styled(LocationLookup)`
  &&& {
    margin-top: 4px;
  }
  &&& .ui.input {
    width: 100%;
  }

  &&& .ui input.prompt {
    border: 2px solid rgba(0, 0, 0, 0.25);
  }
`

const SearchWrapper = styled.div`
  &&& {
    margin: 24px;
  }
`

const StyledLabel = styled.label`
  color: #333;
  font-size: 14px;
  font-weight: normal;
`

export default AssetDrawerForm
