import * as configcat from 'configcat-js'
import { useState } from 'react'

import { CONFIGCAT_KEY } from 'src/constants'
import { useUser as useAuthUser } from 'src/router/UserProvider'

export enum Flags {
  CommsTwoEnabled = 'commsTwoEnabled',
  CombinedLocationServiceLineImports = 'combinedLocationServiceLineImports',
  ConfigureAddressMatching = 'configureAddressMatching',
  Editables = 'editables',
  LslMap = 'showLslProbabilityAndInventoryMap',
  NewServiceLineTable = 'newInventoryTable',
  ShowAllResultsLetter = 'showAllResultsLetter',
  SampleAttachments = 'sampleAttachments',
  StateSubmissionTemplateAttachments = 'stateSubmissionTemplateAttachments',
  ExternalSubmissions = 'externalSubmissions',
  StatePortalPtdSettings = 'statePortalPtdSettings',
  CommunicationsAdminEditorOptions = 'communicationsAdminEditorOptions',
  UseNewExports = 'useNewExports'
}

const DEBUG = 2
const OFF = -1
const logLevel = process.env.NODE_ENV !== 'development' ? OFF : DEBUG
const logger = configcat.createConsoleLogger(logLevel)
const configCatClient = configcat.getClient(
  CONFIGCAT_KEY,
  configcat.PollingMode.AutoPoll,
  { logger: logger }
)

// Because configcat does not export the type for their User class we use any here. :(
const useUser = (): any => {
  const { user } = useAuthUser()
  return {
    custom: { AccountId: user.defaultAccount?.id.toString() },
    email: user.email,
    identifier: user.authId
  }
}

const getValueForFlag = async (
  user: any,
  flag: string,
  defaultValue = false
): Promise<boolean> => {
  const value = await configCatClient.getValueAsync(flag, defaultValue, user)
  return !!value
}

export const useConfig = (flag: string, defaultValue = false): boolean => {
  const user = useUser()
  const [value, setValue] = useState(false)
  getValueForFlag(user, flag, defaultValue).then(setValue)
  return value
}
