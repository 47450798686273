import React, { useCallback, useMemo, useState } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Form, Button } from 'semantic-ui-react'
import styled from 'styled-components'

import { ValidationErrors } from 'final-form'
import Wizard, { Step } from 'src/components/Forms/Wizard'
import { useAccount } from 'src/router/UserProvider'
import { toastError, toastSuccess } from 'src/utils/toast'
import { FEDERAL_STEPS } from '../StateInventory/forms/FederalWizard'
import {
  INDIANA_SIGN_STEP,
  INDIANA_STEPS,
  validateIdentifyServiceLinesCheckboxes
} from '../StateInventory/forms/IndianaWizard'
import { GEORGIA_STEPS } from '../StateInventory/forms/GeorgiaWizard'
import { DELAWARE_STEPS } from '../StateInventory/forms/DelawareWizard'
import {
  ARIZONA_STEPS,
  ARIZONA_SIGN_STEP
} from '../StateInventory/forms/ArizonaWizard'
import CustomerNotification from '../StateInventory/forms/CustomerNotification'
import {
  useTriggerSubmissionMutation,
  useGetAdditionalInfoQuery
} from 'src/services/state-submission-api'
import { AssetStatistics, useGetAssetStatsQuery } from '../dataAccess'
import { isApiError } from 'src/modules/ServiceLines/StateInventory/hooks'
import { TritonError } from 'src/components/Error'
import Row from 'src/components/Row'
import { MiniAttachmentTable } from '../StateInventory/MiniAttachments'
import { MaterialBreakdown } from '../components/MaterialBreakdown'
import { SignatureBlock } from '../components/SignatureBlock'
import { isEmail } from 'src/utils/isEmail'
import { validateDelaware } from '../StateInventory/forms/DelawareWizard/formValidation'
import { validateArizona } from '../StateInventory/forms/ArizonaWizard/Validation'
import { RHODE_ISLAND_STEPS } from '../StateInventory/forms/RhodeIslandWizard'
import { validateRhodeIslandScheema } from '../StateInventory/forms/RhodeIslandWizard/Validation'
import { OREGON_STEPS } from '../StateInventory/forms/OregonWizard'

const generalSignatureStep: Step[] = [
  {
    nextButtonText: 'Next Step',
    title: 'Signature Step',
    render: () => <SignatureBlock />
  }
]

const useWizardSteps = (
  submissionTemplateId: number,
  primacyCode?: string,
  assetStats?: AssetStatistics
) => {
  const {
    galvanizedAssets = 0,
    leadAssets = 0,
    nonLeadAssets = 0,
    totalAssets = 0,
    unknownAssets = 0
  } = assetStats || {}
  let inventorySteps

  let signatureStep = generalSignatureStep
  switch (primacyCode?.toLowerCase()) {
    case 'de':
      inventorySteps = DELAWARE_STEPS
      break
    case 'in':
      inventorySteps = INDIANA_STEPS
      signatureStep = INDIANA_SIGN_STEP
      break
    case 'ga':
      inventorySteps = GEORGIA_STEPS
      break
    case 'az':
      inventorySteps = ARIZONA_STEPS
      signatureStep = ARIZONA_SIGN_STEP
      break
    case 'ri':
      inventorySteps = RHODE_ISLAND_STEPS
      break
    case 'or':
      inventorySteps = OREGON_STEPS
      break
    default:
      inventorySteps = FEDERAL_STEPS
  }

  return useMemo(
    () => [
      ...inventorySteps,
      {
        nextButtonText: 'Next Step',
        title: 'Attachments',
        render: () => (
          <>
            <AssetsExplanation>
              Below is the list of attachments you have added from the State
              Reporting Page. In addition, any attachments added to each Asset
              will also be submitted on their respective Service Lines.
            </AssetsExplanation>
            <MiniAttachmentTable
              relatedEntityId={submissionTemplateId}
              relatedEntityType="submissionTemplates"
            />
          </>
        )
      },
      {
        nextButtonText: 'Next Step',
        title: 'Inventory Count',
        render: () => (
          <MaterialBreakdown
            materialData={{
              totalAssets,
              galvanizedAssets,
              leadAssets,
              nonLeadAssets,
              unknownAssets
            }}
          />
        )
      },
      ...signatureStep
    ],
    [
      submissionTemplateId,
      galvanizedAssets,
      inventorySteps,
      leadAssets,
      nonLeadAssets,
      signatureStep,
      totalAssets,
      unknownAssets
    ]
  )
}

interface Values extends Record<string, any> {
  email?: string
  name?: string
  phone?: string
  signature?: string
  verified?: boolean
}

const useInitialValues = (
  additionalInfo: { [key: string]: any },
  primacyCode?: string
): Values => {
  return useMemo(() => {
    if (primacyCode !== 'IN') {
      return { ...additionalInfo }
    }

    return {
      backflowPreventionDeviceInspection:
        additionalInfo?.backflowPreventionDeviceInspection ?? false,
      cctvInspectionAtCurbBoxExternal:
        additionalInfo?.cctvInspectionAtCurbBoxExternal ?? false,
      cctvInspectionAtCurbBoxInternal:
        additionalInfo?.cctvInspectionAtCurbBoxInternal ?? false,
      customerSelfIdentification:
        additionalInfo?.customerSelfIdentification ?? false,
      mechanicalExcavation: additionalInfo?.mechanicalExcavation ?? false,
      otherInspection: additionalInfo?.otherInspection ?? false,
      otherInvestigation: additionalInfo?.otherInvestigation ?? false,
      predictiveModeling: additionalInfo?.predictiveModeling ?? false,
      serviceLineRepairOrReplacement:
        additionalInfo?.serviceLineRepairOrReplacement ?? false,
      vacuumExcavation: additionalInfo?.vacuumExcavation ?? false,
      visualInspectionAtTheMeterPit:
        additionalInfo?.visualInspectionAtTheMeterPit ?? false,
      waterMainRepairOrReplacement:
        additionalInfo?.waterMainRepairOrReplacement ?? false,
      waterMeterReading: additionalInfo?.waterMeterReading ?? false,
      waterMeterRepairOrReplacement:
        additionalInfo?.waterMeterRepairOrReplacement ?? false,
      waterQualitySamplingFlushed:
        additionalInfo?.waterQualitySamplingFlushed ?? false,
      waterQualitySamplingOther:
        additionalInfo?.waterQualitySamplingOther ?? false,
      waterQualitySamplingSequential:
        additionalInfo?.waterQualitySamplingSequential ?? false,
      waterQualitySamplingTargeted:
        additionalInfo?.waterQualitySamplingTargeted ?? false,
      ...additionalInfo
    }
  }, [primacyCode, additionalInfo])
}

const PHONE_REGEX = /^\d{10}$/

interface Props {
  onClose(): void
  pwsId: number
  latestSubmissionPeriodDueDate: string
}

const StateInventorySubmissionModal = ({
  onClose,
  latestSubmissionPeriodDueDate,
  pwsId
}: Props): JSX.Element => {
  const { primacyCode } = useAccount()
  const isIndianaPrimacyCode = primacyCode === 'IN'
  const isGeorgiaPrimacyCode = primacyCode === 'GA'
  const isDelawarePrimacyCode = primacyCode === 'DE'
  const isArizonaPrimacyCode = primacyCode === 'AZ'
  const isRhodeIslandPrimacyCode = primacyCode === 'RI'
  const { data } = useGetAssetStatsQuery()
  const { data: queryData, isFetching } = useGetAdditionalInfoQuery(pwsId)
  const { data: additionalInfoData, id: submissionTemplateId } =
    queryData?.data ?? { id: 0 }
  const mappedData = useMemo(
    () => (additionalInfoData ? JSON.parse(additionalInfoData) : {}),
    [additionalInfoData]
  )
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)

  const validate = async (values: Values): Promise<ValidationErrors> => {
    const errors: ValidationErrors = {}

    if (!isIndianaPrimacyCode) {
      if (!values.verified) {
        errors.verified = 'Required'
      }

      if (
        !values.signature ||
        (values.signature && values.signature.trim().length === 0)
      ) {
        errors.signature = 'Required'
      }

      if (!values.name || (values.name && values.name.trim().length === 0)) {
        errors.name = 'Required'
      }

      if (!values.phone) {
        errors.phone = 'Required'
      }

      if (values.phone && !PHONE_REGEX.test(values.phone.trim())) {
        errors.phone = 'Invalid, please enter 10 digits'
      }

      if (!values.email) {
        errors.email = 'Required'
      }

      if (values.email && !isEmail(values.email.trim())) {
        errors.email = 'Invalid, please enter a valid email address'
      }
    }

    if (isArizonaPrimacyCode) {
      const arizonaErrors = await validateArizona(values)

      if (arizonaErrors) {
        return { ...errors, ...arizonaErrors }
      }
    }

    if (isRhodeIslandPrimacyCode) {
      const rhodeIslandErrors = await validateRhodeIslandScheema(values)
      if (errors || rhodeIslandErrors) {
        return { ...errors, ...rhodeIslandErrors }
      }
    }

    if (isDelawarePrimacyCode) {
      validateDelaware(values, errors)
    }

    if (isGeorgiaPrimacyCode) {
      if (
        !values.amountSpentOnInventorySubmission ||
        (values.amountSpentOnInventorySubmission &&
          values.amountSpentOnInventorySubmission.trim().length === 0)
      ) {
        errors.amountSpentOnInventorySubmission = 'Required'
      }
    }

    if (isIndianaPrimacyCode) {
      const identifyServiceLinesCheckboxCheck =
        validateIdentifyServiceLinesCheckboxes(values)

      //First Page (Historical Records Review)
      if (
        !values.previousMaterialsEvaluation ||
        (values.previousMaterialsEvaluation &&
          values.previousMaterialsEvaluation.trim().length === 0)
      ) {
        errors.previousMaterialsEvaluation = 'Required'
      }

      if (
        !values.constructionRecordsAndPlumbingCodes ||
        (values.constructionRecordsAndPlumbingCodes &&
          values.constructionRecordsAndPlumbingCodes.trim().length === 0)
      ) {
        errors.constructionRecordsAndPlumbingCodes = 'Required'
      }

      if (
        !values.waterSystemRecords ||
        (values.waterSystemRecords &&
          values.waterSystemRecords.trim().length === 0)
      ) {
        errors.waterSystemRecords = 'Required'
      }

      if (
        !values.distributionSystemInspectionsAndRecords ||
        (values.distributionSystemInspectionsAndRecords &&
          values.distributionSystemInspectionsAndRecords.trim().length === 0)
      ) {
        errors.distributionSystemInspectionsAndRecords = 'Required'
      }

      if (
        !values.historicalReviewOther ||
        (values.historicalReviewOther &&
          values.historicalReviewOther.trim().length === 0)
      ) {
        errors.historicalReviewOther = 'Required'
      }

      //Second Page (Identifying Service Line Material During Normal Operations)

      if (
        values.otherInspection &&
        (!values.otherInspectionExplanation ||
          (values.otherInspectionExplanation &&
            values.otherInspectionExplanation.trim().length === 0))
      ) {
        errors.otherInspectionExplanation = 'Required'
      }

      if (!identifyServiceLinesCheckboxCheck) {
        errors.identifyServiceLinesCheckboxPicklist = 'Required'
      }

      if (!values.operatingProcedures) {
        errors.operatingProcedures = 'Required'
      }

      if (
        values.operatingProcedures === 'Yes' &&
        (!values.operatingProceduresExplanation ||
          (values.operatingProceduresExplanation &&
            values.operatingProceduresExplanation.trim().length === 0))
      ) {
        errors.operatingProceduresExplanation = 'Required'
      }

      //Third Page (Service Line Investigations)

      if (
        values.predictiveModeling &&
        (!values.predictiveModelingExplanation ||
          (values.predictiveModelingExplanation &&
            values.predictiveModelingExplanation.trim().length === 0))
      ) {
        errors.predictiveModelingExplanation = 'Required'
      }

      if (
        values.otherInvestigation &&
        (!values.otherInvestigationExplanation ||
          (values.otherInvestigationExplanation &&
            values.otherInvestigationExplanation.trim().length === 0))
      ) {
        errors.otherInvestigationExplanation = 'Required'
      }

      //Last Page

      if (
        !values.certificationName ||
        (values.certificationName &&
          values.certificationName.trim().length === 0)
      ) {
        errors.certificationName = 'Required'
      }

      if (
        !values.certificationTitle ||
        (values.certificationTitle &&
          values.certificationTitle.trim().length === 0)
      ) {
        errors.certificationTitle = 'Required'
      }

      if (!values.certificationPhone) {
        errors.certificationPhone = 'Required'
      }

      if (
        values.certificationPhone &&
        !PHONE_REGEX.test(values.certificationPhone.trim())
      ) {
        errors.certificationPhone = 'Invalid, please enter 10 digits'
      }

      if (!values.certificationEmail) {
        errors.certificationEmail = 'Required'
      }

      if (
        values.certificationEmail &&
        !isEmail(values.certificationEmail.trim())
      ) {
        errors.certificationEmail =
          'Invalid, please enter a valid email address'
      }

      if (!values.certificationStatement) {
        errors.certificationStatement = 'Required'
      }

      //Optional Phone & Email Validation

      if (
        values.alternativeCertificationPhone &&
        !PHONE_REGEX.test(values.alternativeCertificationPhone.trim())
      ) {
        errors.alternativeCertificationPhone = 'Invalid, please enter 10 digits'
      }

      if (
        values.altenativeCertificationEmail &&
        !isEmail(values.altenativeCertificationEmail.trim())
      ) {
        errors.altenativeCertificationEmail =
          'Invalid, please enter a valid email address'
      }
    }

    return errors
  }

  const [triggerSubmission, { error, isError, isLoading: isSubmitting }] =
    useTriggerSubmissionMutation()

  const submit = useCallback(
    async (values: { [key: string]: any }) => {
      try {
        await triggerSubmission({
          submission: {
            additionalInfo: values
          }
        }).unwrap()

        setHasSubmitted(true)
        toastSuccess('Inventory submitted to State for review')
      } catch {
        toastError('An error has occurred while submitting')
      }
    },
    [triggerSubmission]
  )

  const initialValues = useInitialValues(mappedData, primacyCode)
  const steps = useWizardSteps(submissionTemplateId, primacyCode, data)
  const onSubmit = async (values: any) => {
    await submit(values)
  }

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined
    }

    if (isApiError(error)) {
      return [error.data?.error]
    }

    return ['Please try again or contact support@120water.com']
  }, [error, isError])

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <Form onSubmit={handleSubmit}>
          <Container>
            <h3 style={{ textAlign: 'center', marginTop: '1rem' }}>
              Submit Inventory
            </h3>
            {!hasSubmitted && !errors && (
              <Wizard
                hasProgressBar={true}
                loading={isFetching ?? submitting ?? isSubmitting}
                onCancel={onClose}
                steps={steps}
                valid={valid}
              />
            )}
            {hasSubmitted && !errors && (
              <CustomerNotification
                latestSubmissionPeriodDueDate={latestSubmissionPeriodDueDate}
                onClose={onClose}
              />
            )}
            {errors && (
              <div>
                <TritonError
                  style={{ margin: '12px 24px' }}
                  messages={errors}
                />
                <Row style={{ justifyContent: 'center' }}>
                  <Button onClick={onClose} type="button" primary>
                    Close
                  </Button>
                </Row>
              </div>
            )}
          </Container>
        </Form>
      )}
      validate={validate}
    />
  )
}

const Container = styled.div`
  padding: 36px 40px;

  .wizard-content {
    width: 100%;
  }
`

const AssetsExplanation = styled.p`
  font-size: 14px;
  color: #333;
  font-weight: normal;
  opacity: inherit;
`

export default StateInventorySubmissionModal
